import React, { Children, useEffect, useRef, useState } from 'react'
import { BIOMETRIC, USER_PROFILE, CROSS_BTN } from '../../../Middleware/images';
import "../../../assets/style/addCustomers.css";
import { useNavigate } from 'react-router-dom';
import ReactLoader from '../../../Loader/react-loader';
import ROUTES from '../../../Middleware/routes';
import ERR_MESSAGE from '../../../Helper/error-helper';
import uploadFileToS3 from "../../../Helper/fileUpload"
import { Helmet } from 'react-helmet';
import TITLES from '../../../Helper/title';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePickerValue from '../../../components/date-picker';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const styleLead = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 650,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  border: "1px solid #6ED7EF",
  p: 0,
  outline: 0,
  overflow: "hidden",
  overflowY: "scroll"
};
const AddCustomers = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(null)
  const currentDate = new Date().toISOString().split('T')[0];
  const [trainerList, setTrainerList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [branch, setBranch] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [userChangedInfo, setUserChangedInfo] = useState(false);
  const [openLead, setOpenLead] = useState(false);

  const [info, setInfo] = useState({
    name: "",
    whatsapp_no: "",
    mobile_no: "",
    alternate_no: "",
    date_of_birth: "",
    email: "",
    refer: "",
    height: "",
    weight: "",
    gender: "",
    address: "",
    activity_level: "",
    // training_type: "",
    goal: "",
    sales_person: "",
    branch: localStorage.getItem("branch_id") == 1 && localStorage.getItem("role_id") == 1 ? localStorage.getItem("branch_id") : "",
  });

  const [data1, setData1] = useState({
    discount_type: "",
    discount: "",
    trainer_id: "",
    base_amount: "",
    net_amount: "",
    paid_amount: "",
    balance_amount: "",
    month: "",
    days: "",
    start_date: currentDate,
    end_date: "",
    mode_of_payment: "",
    transaction_id: "",
    plan_type: "1",
    paid_balance_amout: "",
    bill_date: ""
  })

  async function getTrainerApi() {
    setLoading(true)
    let result = await Services.getService(`admin/trainer-list?branch_id=${localStorage.getItem("branchFilter")}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      const filteredTrainer = result.body.data.filter(item => item.role_id == 3)
      setTrainerList(filteredTrainer);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  const setDateOfBirth = (newDate) => {
    setInfo((prevFields) => ({
      ...prevFields,
      date_of_birth: newDate,
    }));
  };
  const nameRef = useRef(null);
  const mobileRef = useRef(null);
  const branchRef = useRef(null);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const emojiSymbolRegex = /[^\p{L}\s]/u;
    const whatsappRegex = /^\d+$/;
    const { name, whatsapp_no, mobile_no, alternate_no, date_of_birth, email, branch
    } = info
    if (!name || name?.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
    }
    else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
    }
    else {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(name) || emojiSymbolRegex.test(name)) {
        formIsValid = false;
        errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
      }
    }

    if (!mobile_no || mobile_no?.trim().length === 0) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.EMPTY_MOBILE
    }
    else if (!whatsappRegex.test(mobile_no)) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (mobile_no.length < 10) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.WHATSAPP_EMPTY
    }
    else if (!whatsappRegex.test(whatsapp_no)) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (whatsapp_no.length < 10) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }

    if (!whatsappRegex.test(alternate_no) && alternate_no?.length >= 1) {
      formIsValid = false;
      errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (alternate_no.length < 10 && alternate_no?.length >= 1) {
      formIsValid = false;
      errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parts = date_of_birth.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    const dob = new Date(year, month, day);
    dob.setHours(0, 0, 0, 0);

    if (dob.getTime() >= today.getTime()) {
      formIsValid = false;
      errors["date_of_birth"] = ERR_MESSAGE.INVALID_DATEOFBIRTH;
    }
    if (reg.test(email.toLowerCase()) === false && email.length > 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    if (!branch) {
      formIsValid = false;
      errors["branch"] = ERR_MESSAGE.EMPTY_BRANCH;
    }
    const firstErrorField = Object.keys(errors)[0];
    const firstErrorRef =
      firstErrorField === 'name' ? nameRef :
        firstErrorField === 'mobile_no' ? mobileRef :
          firstErrorField === 'branch' ? branch :
            null;

    if (firstErrorRef && firstErrorRef.current) {
      firstErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setErrors(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    const regex = /^[0-9]*$/;

    if (name === 'mobile_no' && !regex.test(value)) {
      return;
    }
    if (name === 'whatsapp_no' && !regex.test(value)) {
      return;
    }
    if (name === 'alternate_no' && !regex.test(value)) {
      return;
    }

    if (name === 'name') {
      const capitalizedValue = value
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setInfo({
        ...info,
        [name]: capitalizedValue,
      });
    } else if (name === 'mobile_no') {
      setInfo({
        ...info,
        [name]: value,
        whatsapp_no: value,
      });
    } else {
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };
  const setBillSince = (newDate) => {
    setData1((prevFields) => ({
      ...prevFields,
      bill_date: newDate,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const formattedValue = parseFloat(value).toFixed(2);

    setInfo({
      ...info,
      [name]: formattedValue,
    });
  };

  const fileChange = (e) => {
    setErrors("");
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        const imageURL = URL.createObjectURL(file);
        setImageUrl(imageURL);
        fileUpload(file)
      }
    }
  };

  const fileUpload = async (file) => {
    try {
      if (file) {
        const fileUrl = await uploadFileToS3(file);
        setFileUrl(fileUrl)
      }
    }
    catch (error) {
    }
  };

  async function getBranchApi() {
    const company_id = localStorage.getItem("company_id")
    let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setBranch(result?.body?.branch)
    }
    else {
    }
  }

  useEffect(() => {
    getBranchApi()
    getTrainerApi()
  }, [])

  const handleOpenLead = () => {
    // setId(id)
    setData1({
      start_date: currentDate
    })
    setOpenLead(true);

  };

  const handleCloseLead = () => {
    setOpenLead(false)
    setData1({
      ...data1,
      discount_type: "",
      discount: "",
      base_amount: "",
      net_amount: "",
      paid_amount: "",
      balance_amount: "",
      month: "",
      days: "",
      start_date: "",
      end_date: "",
      mode_of_payment: "",
      transaction_id: ""
    })
  };

  const handleChange2 = (e) => {
    setErrors('');
    const name = e.target.name;
    let value = e.target.value;
    if ((name === 'days' || name === 'month' || name === "base_amount" || name === 'net_amount' || name === 'paid_amount') && parseFloat(value) < 0) {
      value = 0;
    }
    if (name === 'month') {
      value = value.replace(/\./g, '');
    }
    if (name === 'month' && parseFloat(value) > 12) {
      value = 0;
    }
    if (name === 'discount') {
      const baseAmount = parseFloat(data1?.base_amount);
      value = Math.min(parseFloat(value), baseAmount);
    }
    if (name === 'net_amount') {
      const baseAmount = parseFloat(data1?.base_amount);
      value = Math.min(parseFloat(value), baseAmount);
    }
    if (name === 'paid_amount') {
      const netAmount = parseFloat(data1?.net_amount);
      value = Math.min(parseFloat(value), netAmount);
    }
    setData1((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    if (name === 'discount_type' && value === '2') {
      setData1((prevInfo) => ({
        ...prevInfo,
        discount: '',
      }));
    }

    if (name === 'base_amount' || name === 'discount_type' || name === 'discount') {
      calculateNetAmount();
    }

    if (name === 'net_amount' || name === 'paid_amount') {
      calculateBalance();
    }
    if (name === 'discount_type' && value === '1') {
      if (name === 'discount' && value > 100) {
        setData1((prevInfo) => ({
          ...prevInfo,
          discount: 100,
        }));
      }
    }

    if (name === "month" || name === "days") {
      setUserChangedInfo(true);
    }
  };

  const calculateNetAmount = () => {
    const baseAmount = Number(data1?.base_amount) || 0;
    const discount = Number(data1?.discount) || 0;
    if (data1?.discount_type === '1') {
      const discountAmount = (baseAmount * discount) / 100;
      const netAmount = baseAmount - discountAmount;
      setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
    }
    else if (data1?.discount_type === '2') {
      const discountAmount = data1.discount
      const netAmount = baseAmount - discountAmount;
      setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
    }
    else {
      setData1((prevInfo) => ({ ...prevInfo, net_amount: data1?.base_amount, paid_amount: data1?.base_amount }));
    }
  };

  const calculateBalance = () => {
    const netAmount = Number(data1?.net_amount) || 0;
    const paid_amount = Number(data1?.paid_amount) || 0;
    const balance_amount = netAmount - paid_amount;
    setData1((prevInfo) => ({ ...prevInfo, balance_amount: balance_amount }));
  };

  const handleStart = (newDate) => {
    if (data1.month > 0 || data1.days > 0) {
      const startDate = new Date(newDate) || currentDate;
      const month = parseInt(data1?.month || 0);
      const days = parseInt(data1?.days || 0);
      const totalDays = month * 29 + days;
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + totalDays);
      const formattedEndDate = endDate.toISOString().split('T')[0];
      setData1((prevFields) => ({
        ...prevFields,
        start_date: newDate,
        end_date: formattedEndDate,
      }));
    }
    else {
      setData1((prevFields) => ({
        ...prevFields,
        start_date: newDate,
        end_date: "",
      }));
    }
  };

  useEffect(() => {
    calculateNetAmount();
  }, [data1?.base_amount, data1?.discount_type, data1?.discount]);

  useEffect(() => {
    calculateBalance();
  }, [data1?.net_amount, data1?.paid_amount, data1?.base_amount]);


  useEffect(() => {
    setData1((prevInfo) => ({ ...prevInfo, paid_amount: data1?.net_amount }));
  }, [data1?.net_amount]);

  useEffect(() => {
    if (userChangedInfo) {
      handleStart(data1.start_date);
      setUserChangedInfo(false);
    }
  }, [data1?.month, data1?.days, userChangedInfo]);



  const setMemberSince = (newDate) => {
    setData1((prevFields) => ({
      ...prevFields,
      end_date: newDate,
    }));
  };

  async function postApi() {
    let data = {
      "company_id": localStorage.getItem("company_id"),
      // "branch_id": localStorage.getItem("branch_id"),
      "profile_pic": fileUrl,
      "name": info?.name,
      "whatsapp_no": info.whatsapp_no,
      "alternate_no": info.alternate_no,
      "date_of_birth": info.date_of_birth,
      "email": info?.email,
      "address_1": info?.address,
      "registration_id": info?.registration_id,
      "activity_level": info?.activity_level,
      "prospect_source": info.prospect_source,
      "goal": info.goal,
      "sales_person": info.sales_person,
      "trainer_id": "1",
      "mobile_number": info.mobile_no,
      "biometric_id": "",
      "branch_id": info.branch,
      "plan_type": data1.plan_type ? data1.plan_type : "1",
      "discount_type": data1.discount_type ? data1.discount_type : null,
      "discount": data1.discount ? data1.discount : null,
      "base_amount": data1.base_amount,
      "net_amount": data1.net_amount,
      "paid_amount": data1.paid_amount,
      "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
      "month": data1.month,
      "days": data1.days ? data1.days : "0",
      "start_date": data1.start_date,
      "end_date": data1.end_date,
      "mode_of_payment": data1.mode_of_payment,
      "transaction_id": data1.transaction_id ? data1.transaction_id : null,
      "trainer_id": data1.trainer_id ? data1.trainer_id : null,
      "billing_date": data1.bill_date
    };

    if (info.gender) {
      data.gender = info.gender;
    }
    if (info.height) {
      data.height = info.height;
    }
    if (info.weight) {
      data.weight = info.weight;
    }
    if (info.refer) {
      data.refer_by = info.refer;
    }

    setLoading(true);
    const result = await Services.postService("admin/add-customer", JSON.stringify(data));
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      localStorage.setItem("message", result.message)
      navigate(ROUTES.CUSTOMERS)
    }
    else {
      toast.error(result.message);
      setLoading(false);
    }
  }


  async function getApi() {
    setLoading(true)
    let result = await Services.getService(`admin/get-customers?company_id=${localStorage.getItem("company_id")}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setCustomerList(result?.body?.data);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getApi()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (handleValidation()) {
      handleOpenLead()
    }
  };

  const handleValidation1 = () => {
    let errors = {};
    let formIsValid = true;
    const { base_amount,
      month,
      paid_amount,
      net_amount,
      mode_of_payment,
      start_date,
      trainer_id,
      bill_date
    } = data1
    if (data1.plan_type == '2') {
      if (!trainer_id || trainer_id == undefined) {
        formIsValid = false;
        errors["trainer_id"] = ERR_MESSAGE.EMPTY_TRAINER
      }
    }
    if (!base_amount || base_amount.length === 0) {
      formIsValid = false;
      errors["base_amount"] = ERR_MESSAGE.EMPTY_BASEAMOUNT;
    }
    if (!net_amount || net_amount.length === 0) {
      formIsValid = false;
      errors["net_amount"] = ERR_MESSAGE.EMPTY_NETAMOUNT;
    }
    if (!paid_amount || paid_amount.length === 0) {
      formIsValid = false;
      errors["paid_amount"] = ERR_MESSAGE.EMPTY_PAIDAMOUNT;
    }
    if (!month) {
      formIsValid = false;
      errors["month"] = ERR_MESSAGE.EMPTY_PLANPERIOD;
    }
    if (!bill_date || bill_date.length === 0) {
      formIsValid = false;
      errors["bill_date"] = ERR_MESSAGE.EMPTY_DATE;
    }

    if (!mode_of_payment || mode_of_payment.length === 0) {
      formIsValid = false;
      errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
    }
    setErrors(errors);
    return formIsValid;
  }


  async function postMembershipApi(e) {
    e.preventDefault()
    if (handleValidation1()) {
      postApi()
    }
  }

  return (
    <div>
      <Helmet>
        <title>{TITLES.ADD_CUSTOMER}</title>
      </Helmet>
      {loading && <ReactLoader />}
      <section className="add-customer common-bg form-height my-3 mx-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="add-customer-head mb-2 mb-lg-4">
                <h2 className="add-customer-text fw-600">Add Customer</h2>
              </div>
            </div>
            <div className="col-12">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item custom-accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button fw-600 custom-accordion-btn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      BASIC DETAIL
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"  aria-labelledBy="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <form>
                        <div className="row">
                          <div className="col-12 col-lg-2">
                            <div className=" position-relative">
                              <div className={`profile-pic  ${imageUrl !== "" ? 'profile-pic-change' : ''}`}>
                                <div className={imageUrl === "" ? "profile-upper-pic" : ""}>
                                  <img src={imageUrl ? imageUrl : USER_PROFILE} className={`img-fluid  ${imageUrl !== "" ? 'img-preview ' : ""}`} id="blah" />
                                  <p className="f14-size text-black fw-600">{imageUrl ? "" : "Upload Image"}</p>
                                </div>
                              </div>
                              <input type="file" className="input-profile" id="image" accept=".jpg, .jpeg, .png" onChange={fileChange} />
                              {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}
                            </div>
                          </div>
                          <div className="col-12 col-lg-10">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4" ref={nameRef}>
                                <div className="mb-3">
                                  <label className="form-label">name</label>
                                  <input type="text" name='name' value={info.name} onChange={handleChange} className="form-control common-input" placeholder="Enter Name" />
                                  {errors.name && <span className="err_msg">{errors.name}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label" ref={mobileRef}>Mobile No</label>
                                  <input type="number" name='mobile_no' value={info.mobile_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter mobile number" />
                                  {errors.mobile_no && <span className="err_msg">{errors.mobile_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">Whatsapp No</label>
                                  <input type="number" name='whatsapp_no' value={info.whatsapp_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Whatsapp number" />
                                  {errors.whatsapp_no && <span className="err_msg">{errors.whatsapp_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">ALternate No</label>
                                  <input type="number" name='alternate_no' value={info.alternate_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Alternate No" />
                                  {errors.alternate_no && <span className="err_msg">{errors.alternate_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <div className="mb-3" ref={branchRef}>
                                    <label className="form-label">Branch</label>
                                    {
                                      localStorage.getItem("role_id") == 1
                                        ?
                                        <select type="text" name='branch' value={info.branch} onChange={handleChange} className="form-select common-input" disabled aria-label="Enter Role">

                                          {
                                            branch.filter(item => item.id == localStorage.getItem("branch_id"))?.map((item) => {
                                              return (
                                                <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                              )
                                            })
                                          }
                                        </select> :
                                        <select type="text" name='branch' value={info.branch} onChange={handleChange} className="form-select common-input" aria-label="Enter Role">
                                          <option value="">Select</option>
                                          {
                                            branch?.map((item) => {
                                              return (
                                                <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                              )
                                            })
                                          }
                                        </select>
                                    }

                                    {errors.branch && <span className="err_msg">{errors.branch}</span>}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">Refer By</label>
                                  <select name='refer' value={info.refer} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                    <option value="">Select Customer</option>
                                    {
                                      customerList?.map((item) => {
                                        return (
                                          <>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                          </>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="accordion-item custom-accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button fw-600 custom-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      OTHER DETAIL
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"  aria-labelledBy="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                      <form>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">DOB</label>

                                  <DatePickerValue
                                    setErrors={setErrors}
                                    value={info.date_of_birth}
                                    setFn={setDateOfBirth}
                                  />
                                  {errors.date_of_birth && <span className="err_msg">{errors.date_of_birth}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Height (CM)</label>
                                  <input type="number" name='height' value={info.height} onChange={handleChange} onBlur={handleBlur} className="form-control common-input" placeholder="Enter Height" />

                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Weight(kg)</label>
                                  <input type="number" name='weight' value={info.weight} onBlur={handleBlur}
                                    onChange={handleChange} className="form-control common-input" placeholder="Enter Weight" />
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Email</label>
                                  <input type="text" name='email' value={info.email} onChange={handleChange} className="form-control common-input" placeholder="Enter Email" />

                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Gender</label>
                                  <select name='gender' value={info.gender} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                    <option value="">Select</option>
                                    <option value="2">Female</option>
                                    <option value="1">Male</option>
                                    <option value="3">Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Address</label>
                                  <input type="text" name='address' value={info.address} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Activity Level</label>
                                  <select name='activity_level' value={info?.activity_level} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected >Select</option>
                                    <option value="Rookie">Rookie</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advance">Advance</option>
                                    <option value="True Beast">True Beast</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Goal</label>
                                  <select name='goal' value={info?.goal} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected>Select</option>
                                    <option value="Gain Weight">Gain Weight</option>
                                    <option value="Lose Weight">Lose Weight</option>
                                    <option value="Get fitter">Get fitter</option>
                                    <option value="Gain more flexibility">Gain more flexibility</option>
                                    <option value="Learn the basics">Learn the basics</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Sale Rep</label>
                                  <select name='sales_person' value={info?.sales_person} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected >Select</option>
                                    <option value="Aditi Roy">Aditi Roy</option>
                                    <option value="karan Verma">karan Verma</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mb-3 custom-biometric">
                                  <label className="form-label">Biometric</label>
                                  <div className="Biometric-img text-center common-input">
                                    <img src={BIOMETRIC} className="img-fluid" />
                                    <p className="add-biometric pt-2">Add</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-end mt-3 px-3 px-sm-0">
                <button type="button" className="next-btn" onClick={handleSubmit}>Next</button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openLead}
           aria-labelledBy="modal-modal-title"
          aria-describedBy="modal-modal-description"
        >
          <Box sx={styleLead} className="modal-head">
            <div
              onClick={handleCloseLead}
              className=" position-relative mb-0 text-end pt-1 pe-1"
              style={{ cursor: "pointer" }}
            >
              <img src={CROSS_BTN} />
            </div>
            <div className="signin-head text-center pb-3">
              <h2 className='modalHeading'>Membership Plan</h2>
            </div>

            <form className='create-field purchase-create-field'>
              <div className='row'>
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0">Training type </label>
                    <select name="plan_type" className="form-select common-input" value={data1?.plan_type} onChange={handleChange2} >
                      <option value="1">General training</option>
                      <option value="2">Personal training</option>
                    </select>
                  </div>
                </div>
                {
                  data1?.plan_type == 2 && <div className="col-md-12">
                    <div className="mb-2 mb-xxl-3">
                      <label className="form-label mb-0">Trainer </label>

                      <select name='trainer_id' value={data1.trainer_id} onChange={handleChange2} className="form-select common-input" aria-label="Default select example" >
                        <option value="">Select Trainer</option>
                        {
                          trainerList?.map((item) => {
                            return (
                              <>
                                <option key={item.id} value={item.id}>{item.name}</option>
                              </>
                            )
                          })
                        }

                      </select>
                      {errors.trainer_id && <span className="err_msg">{errors.trainer_id}</span>}
                    </div>
                  </div>
                }

                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0">Base Amount</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className='dollar-Sign'>₹</span>
                      </div>
                      <input
                        type="number"
                        name="base_amount"
                        value={data1?.base_amount?.slice(0, 15)}
                        onChange={handleChange2}
                        className="form-control common-input dollarInput"
                        placeholder="Enter Price"
                      />
                    </div>
                    {errors.base_amount && <span className="err_msg">{errors.base_amount}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0">DISCOUNT type </label>
                    <select name="discount_type" className="form-select common-input" value={data1?.discount_type} onChange={handleChange2} >
                      <option value="">Select</option>
                      <option value="1">%(Percentage)</option>
                      <option value="2">Lump sum</option>
                    </select>
                    {errors.discount_type && <span className="err_msg">{errors.discount_type}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">DISCOUNT</label><input type="number" className="form-control common-input" placeholder="Enter Discount" value={data1?.discount} onChange={handleChange2} name="discount" disabled={!data1?.discount_type} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Net amount</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className='dollar-Sign'>₹</span>
                      </div>
                      <input
                        type="number"
                        name="net_amount"
                        value={data1?.net_amount}
                        onChange={handleChange2}
                        readOnly={data1?.discount_type == "1" || !data1?.discount_type || data1.discount_type == "2"} className="form-control common-input dollarInput"
                        placeholder="Enter Price"
                      />
                    </div>
                    {errors.net_amount && <span className="err_msg">{errors.net_amount}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">PAID amount</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className='dollar-Sign'>₹</span>
                      </div>
                      <input
                        type="number" placeholder="Enter paid amount" value={data1?.paid_amount} onChange={handleChange2} name="paid_amount"
                        className="form-control common-input dollarInput"
                      />
                    </div>
                    {errors.paid_amount && <span className="err_msg">{errors.paid_amount}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className='dollar-Sign'>₹</span>
                      </div>
                      <input
                        type="number" placeholder="Enter balance amount" value={data1?.balance_amount}

                        name="balance_amount"
                        className="form-control common-input dollarInput"
                        onChange={(e) => {
                          handleChange2(e);

                        }}
                      />
                    </div>

                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">MONTH</label><input type="number" className="form-control common-input" placeholder="Enter Month" value={data1?.month} onChange={handleChange2} name="month" />
                    {errors.month && <span className="err_msg">{errors.month}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Days</label><input type="number" onBlur={handleBlur} className="form-control common-input" placeholder="Enter days" value={data1?.days}
                    onChange={handleChange2} name="days" />

                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">START date</label>
                    <DatePickerValue
                      setErrors={setErrors}
                      value={data1?.start_date ? data1.start_date : currentDate}
                      setFn={handleStart}
                    />
                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                    <DatePickerValue
                      setErrors={setErrors}
                      value={data1?.end_date}
                      setFn={setMemberSince}
                      endDate={data1?.end_date}
                      name="planEnd"
                    />
                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Billing date</label>
                    <DatePickerValue
                      setErrors={setErrors}
                      value={data1?.bill_date}
                      setFn={setBillSince}
                    />
                    {errors.bill_date && <span className="err_msg">{errors.bill_date}</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0">PAYMENT MODE</label>
                    <select name="mode_of_payment" className="form-select common-input" value={data1?.mode_of_payment} onChange={handleChange2} >
                      <option selected>Select</option>
                      <option value="cash">Cash</option>
                      <option value="gpay">Gpay</option>
                      <option value="swipe_machine">Swipe Machine</option>
                    </select>
                    {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={data1?.transaction_id} onChange={handleChange2} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                    {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                  </div>

                </div>
              </div>


            </form>
            <div className="mt-4 mb-3 text-center position-relative submit-head">
              <button onClick={postMembershipApi}
                type="submit"
                className="common-btnn w-100"
              >
                Submit
              </button>
            </div>
          </Box>

        </Modal>
      </section>


      <ToastContainer />
    </div>
  )
}

export default AddCustomers;