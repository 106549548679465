import React, { useEffect, useRef, useState } from 'react';
import { BILLING, DELETE_ICON, DOWNLOAD, PRINT, CROSS_BTN, SEND_EMAIL } from '../Middleware/images';
import moment from "moment";
import Pagination from './pagination';
import Invoice from './invoice';
import html2pdf from 'html2pdf.js';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Services from '../Helper/services';
import DEVELOPMENT_CONFIG from '../Helper/config';
import ReactLoader from '../Loader/react-loader';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 700,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

function Bills(props) {
    const invoiceRef = useRef();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const printIframeRef = useRef();
    const [openPrint, setOpenPint] = useState(false)
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [pdfBlobUrl, setPdfBlobUrl] = useState('');
    const [base64Url, setBase64Url] = useState('');
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setEmail(props.email);
    }, [props.email])

    useEffect(() => {
        const element = invoiceRef.current;
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/; 
        if (!reg.test(email)) {
            setLoader(false);
            return;
        }
        if (element) {
            setLoader(true)
            html2pdf()
                .from(element)
                .set({
                    jsPDF: {
                        format: "a4",
                        orientation: "portrait",
                        unit: "mm",
                        compress: true
                    },
                    html2canvas: {
                        scale: 1,
                        logging: true,
                        useCORS: true
                    }
                })
                .output("blob")
                .then((pdfBlob) => {
                    const url = URL.createObjectURL(pdfBlob);
                    setPdfBlobUrl(url);

                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        setBase64Url(base64String)
                        setLoader(false)
                    };
                    reader.readAsDataURL(pdfBlob);
                })
                .catch((err) => console.error("PDF generation error: ", err));
        } else {
            console.error("Invoice reference is not set.");
        }
    }, [email]);

    const handleOpen = (item) => {
        setOpen(true);
        setInvoiceDetails(item);
        setEmail(props.email);
        const element = invoiceRef.current;
        setLoader(true)

        if (element) {
            html2pdf()
                .from(element)
                .set({
                    jsPDF: {
                        format: "a4",
                        orientation: "portrait",
                        unit: "mm",
                        compress: true
                    },
                    html2canvas: {
                        scale: 1,
                        logging: true,
                        useCORS: true
                    }
                })
                .output("blob")
                .then((pdfBlob) => {
                    const url = URL.createObjectURL(pdfBlob);
                    setPdfBlobUrl(url);

                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        setBase64Url(base64String)
                        setLoader(false)

                    };
                    reader.readAsDataURL(pdfBlob);
                })
                .catch((err) => console.error("PDF generation error: ", err));
        } else {
            console.error("Invoice reference is not set.");
        }
    };


    const handleClose = () => {
        setOpen(false);
        setError("")
        setEmail('')

    };

    const handleDownload = (item) => {
        const element = invoiceRef.current;
        setInvoiceDetails(item);
        if (element) {
            setTimeout(() => {
                html2pdf()
                    .from(element)
                    .set({
                        margin: 1,
                        filename: `${item.user.name}_invoice.pdf`,
                        html2canvas: { scale: 2, logging: true, useCORS: true },
                        jsPDF: { format: 'a4', orientation: 'portrait' }
                    })
                    .save()
                    .catch(err => console.error("PDF generation error: ", err));
            }, 500);
        } else {
            console.error("Invoice reference is not set.");
        }
    };

    const handlePrint = (item) => {
        setOpenPint(true)
        setInvoiceDetails(item);
    };

    useEffect(() => {
        if (openPrint) {
            setOpenPint(false)
            const iframe = printIframeRef.current;
            const content = invoiceRef.current.innerHTML;
            const doc = iframe.contentWindow.document;
            doc.open();
            doc.write('<html><head><title>Print Invoice</title>');
            doc.write('<link rel="stylesheet" href="your-stylesheet.css">');
            doc.write('</head><body>');
            doc.write(content);
            doc.write('</body></html>');
            doc.close();
            iframe.onload = function () {
                iframe.contentWindow.print();
            };
        }
    }, [openPrint])

    const handleSend = async (e) => {
        e.preventDefault();
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (!email) {
            setError('Enter Email');
        } else if (reg.test(email.toLowerCase()) === false && email.length > 0) {
            setError('Enter valid email');
        } else {
            const formData = new FormData();

            formData.append('email', email);
            formData.append('bill_id', invoiceDetails.id);
            formData.append('customer_id', props.customerId);
            formData.append('pdf_string', base64Url);

            setLoading(true);

            try {
                const result = await Services.postServiceIEmail("admin/send-invoice", formData);

                if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                    setLoading(false);
                    toast.success(result.message);
                    handleClose();
                    props.getBillApi(props.currentPage)
                } else {
                    toast.error(result.message);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);

            }
        }
    };
    return (
        <>
            {loading && <ReactLoader />}

            <div className="row h-100 align-items-center">
                <div className="col-12">
                    {
                        props?.bill?.length === 0 ? (
                            <div className="no-data-content text-center">
                                <img src={BILLING} className="img-fluid" />
                            </div>
                        ) : (
                            <div className="table-responsive leads-table-responsive member-table-top">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="leads-table-head">Billing  date</th>
                                            <th scope="col" className="leads-table-head">Bill No</th>
                                            <th scope="col" className="leads-table-head">Start date</th>
                                            <th scope="col" className="leads-table-head">End date</th>
                                            <th scope="col" className="leads-table-head">Base amount</th>
                                            <th scope="col" className="leads-table-head">Discount</th>
                                            <th scope="col" className="leads-table-head">Net amount</th>
                                            <th scope="col" className="leads-table-head">Paid amount</th>
                                            <th scope="col" className="leads-table-head">Balance Amount</th>
                                            <th scope="col" className="leads-table-head">Payment Mode</th>
                                            <th scope="col" className="leads-table-head">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.bill?.map((item) => {
                                                const discountPercentage = ((item.base_amount - item.net_amount) / item.base_amount) * 100;
                                                const roundedDiscountPercentage = discountPercentage % 1 === 0 ? discountPercentage.toFixed(0) : discountPercentage.toFixed(2);
                                                return (
                                                    <tr key={item?.id}>
                                                        <td className="leads-table-data text-start">{moment(item.billing_date).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{item?.bill_number}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.start_date ? moment(item?.membership_details?.start_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.end_date ? moment(item?.membership_details?.end_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{`₹${item?.base_amount}`}</td>
                                                        <td className="leads-table-data ">
                                                            {/* {discountPercentage === 0 ? "-" : `${roundedDiscountPercentage}%`} */}
                                                            {item.discount_type == 2 && "₹"} {item.discount ? item.discount : "-"}{item.discount_type == 1 ? "%" : ""}
                                                        </td>
                                                        <td className="leads-table-data">{`₹${item?.net_amount}`}</td>
                                                        <td className="leads-table-data">{`₹${item?.paid_amount}`}</td>
                                                        <td className="leads-table-data">{item?.balance_amount === "0" ? "₹0" : `₹${item?.balance_amount}`}</td>
                                                        <td className="leads-table-data">{item?.mode_of_payment}</td>
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                                                <img
                                                                    src={DELETE_ICON}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => props.handleBillOpen(item.id)}
                                                                />
                                                                {
                                                                    item.status !== 3 && (
                                                                        <img
                                                                            src={PRINT}
                                                                            style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                            onClick={() => handlePrint(item)}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                }
                                                                <img
                                                                    src={DOWNLOAD}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => handleDownload(item)}
                                                                />
                                                                <img
                                                                    src={SEND_EMAIL}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => handleOpen(item)}
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )

                    }
                    {
                        props?.bill?.length > 0 && <>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <a href=""><span className="page-entry">
                                        {
                                            props.total === 1 ? <>
                                                Showing {props.total1} entry
                                            </> :
                                                <>
                                                    Showing {props.startIndex} to {props.endIndex1} of {props.total1} entries
                                                </>
                                        }
                                    </span>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <nav aria-label="Page">
                                        <ul className="pagination d-flex justify-content-end">
                                            <Pagination currentPage={props.currentPage} totalPages={props.totalPages1} onPageChange={props.handlePageChange} />
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </>
                    }
                    {
                        invoiceDetails &&
                        <div style={{ display: 'none' }}>
                            <div ref={invoiceRef}>
                                <Invoice
                                    invoiceDetails={invoiceDetails}
                                    gymDetail={props.gymDetail}
                                    email={email}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <iframe
                ref={printIframeRef}
                style={{ display: 'none' }}
                title="Print Invoice"
            />
            <Modal
                open={open}
                aria-labelledBy="modal-modal-title"
                aria-describedBy="modal-modal-description"
            >
                <Box sx={style} className="modal-head purchase-modal">
                    <div
                        onClick={handleClose}
                        className=" position-relative mb-0 text-end pt-1 pe-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    {/* <div className="signin-head text-center ">
                        <h2 className='modalHeading'>Invoice Send</h2>
                    </div> */}

                    <form className='create-field ' style={{
                        height: 550,
                        overflowY: 'auto'
                    }}>

                        <div className="col-md-12">
                            <div className="mb-2 mb-xxl-3">
                                <label className="form-label mb-0">Customer Email</label>

                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => {
                                        setError('')
                                        setEmail(e.target.value)
                                    }}
                                    className="form-control common-input "
                                    placeholder="Enter Email"
                                />

                                {error && <span className="err_msg">{error}</span>}
                            </div>
                        </div>
                        <div className="col-md-12">

                            {loader ? <div className='d-flex justify-content-center align-items-center' style={{marginTop:"30vh"}}>
                                <p className='text-black fw-500' style={{fontSize:"20px"}}>PDF Loading...</p>
                                </div>:
                                 (
                                <iframe
                                    src={`${pdfBlobUrl}#toolbar=0&page=1`}
                                    width="100%"
                                    height="460px"
                                    style={{ border: "none" }}
                                    title="Invoice Preview"
                                ></iframe>
                            )}
                        </div>
                    </form>
                    <div className="mt-4 mb-3 mx-4 text-center position-relative submit-head">
                        <button
                            onClick={handleSend}
                            type="submit"
                            className="common-btnn w-100"
                        >
                            Send
                        </button>
                    </div>

                </Box>
            </Modal>
            <ToastContainer />

        </>
    );
}

export default Bills;
